@charset "utf-8";

/* CSS Document */
html, body, div, span, object, iframe,h1, h2, h3, h4, h5, h6, p, blockquote, pre,abbr, address, cite, code, 
del, dfn, em, img, ins, kbd, q, samp,small, strong, sub, sup, var,b, i, dl, dt, dd, ol, ul, li,fieldset, 
form, label, legend,table, caption, tbody, tfoot, thead, tr, th, td,article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,time, mark, audio, video { margin: 0; padding: 0; border: 0; outline: 0; font-size: 100%; vertical-align: baseline; }
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section { display: block; }
blockquote, q { quotes: none; }
blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }
input, textarea { margin: 0; padding: 0; }
a { text-decoration: none; }
a, button, input[type=submit], select, label { cursor: pointer; }
fieldset, img { border: 0; }
ol, ul { list-style: none; }
abbr, acronym { border: 0; }

.clearfix:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }
* html .clearfix { height: 1%; }
*:first-child + html .clearfix { min-height: 1%; }

a{ text-decoration:none; outline: none; transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
a:hover{ text-decoration: none; }
*:link, *:visited, *:hover, *:active, *:focus, 
* {  -webkit-text-size-adjust: none; border: none; outline: none; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
input:not([type="radio"],[type="checkbox"]) { border-radius: 0; appearance: none; -webkit-appearance: none; -moz-appearance: none; }
select{ border-radius: 0; appearance: none; -moz-appearance: none; -webkit-appearance: none; -ms-appearance: none; }
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/*webkit瀏覽器專用*/
* ::-webkit-input-placeholder { color: #A5A5A5; line-height: 20px; }
/*Firefox 4-18瀏覽器專用*/
* input::-moz-placeholder { color: #A5A5A5; line-height: 20px; }
/*Firefox 19+瀏覽器專用*/
* input::-moz-placeholder{ color: #A5A5A5; line-height: 20px; }
/*IE10瀏覽器專用*/
*:-ms-input-placeholder{ color: #A5A5A5; line-height: 20px; }
@charset "utf-8";
@import url("reset.css");
@import url("swiper.min.css");
@import url("jquery.modal.min.css");
@font-face {
	font-family: 'Noto Sans TC';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("https://fonts.gstatic.com/s/notosanstc/v11/-nF7OG829Oofr2wohFbTp9i1OCEJ.otf") format('opentype')
}

@font-face {
	font-family: 'Noto Sans TC';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("https://fonts.gstatic.com/s/notosanstc/v11/-nFkOG829Oofr2wohFbTp9i9ywIfDNdX.otf") format('opentype')
}

@font-face {
	font-family: 'Noto Sans TC';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("https://fonts.gstatic.com/s/notosanstc/v11/-nFkOG829Oofr2wohFbTp9i9gwQfDNdX.otf") format('opentype')
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxP.ttf") format('truetype')
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmEU9fBBc9.ttf") format('truetype')
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBBc9.ttf") format('truetype')
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
select,
input,
textarea,
button {
	font-family: "Roboto", "Noto Sans TC", "Microsoft JhengHei", "黑體", sans-serif, serif;
	color: #474747;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: .5px
}

.jquery-modal.blocker {
	padding: 10px;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 3
}

.jquery-modal.blocker .modal {
	width: 100%;
	max-width: 600px;
	min-height: calc(100% - 20px);
	padding: 15px 25px 95px 25px;
	color: #474747;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	border-radius: 0
}



.jquery-modal.blocker .modal .close-modal {
	width: 36px !important;
	height: 36px !important;
	top: 10px !important;
	right: 10px !important;
	background: #f3f3f3 url("../images/svg/close.svg") no-repeat center !important;
	background-size: 30% !important;
	border-radius: 99em;
	-webkit-border-radius: 99em;
	-moz-border-radius: 99em
}

main {
	padding-bottom: 80px;
	z-index: 0
}

.container {
	width: 100%;
	margin: 0 auto
}

.flex_center {
	display: flex;
	justify-content: center;
	align-items: center
}

.flex_top {
	display: flex;
	align-items: flex-start
}

.flex_wrap {
	flex-flow: wrap
}

.hide {
	display: none
}

.visible_hide {
	visibility: hidden
}

.visible_show {
	visibility: visible
}

.loading_area {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	color: #979797;
	background-color: rgba(255, 255, 255, 0.9);
	z-index: 5
}

.loader {
	width: 40px;
	height: 40px;
	margin: 5px;
	border: 6px solid #C4C4C4;
	border-top: 6px solid #474747;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	animation: loading 2s linear infinite
}

@keyframes loading {
	0% {
		transform: rotate(0deg)
	}
	100% {
		transform: rotate(360deg)
	}
}

.overlay_for_mobile {
	display: none;
	position: fixed;
	width: 100%;
	height: 100vh;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	color: #f0941d;
	background-color: #282828;
	z-index: 5
}

.overlay_for_mobile .attention {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center
}

@media (max-width:640px) {
	.overlay_for_mobile {
		display: block
	}
}

.pin_login {
	width: 100%;
	height: 100vh;
	background: #f0941d
}

.pin_login .logo {
	width: 100%;
	height: 80px
}

.pin_login .logo img {
	display: block;
	width: auto;
	height: 100%;
	margin: 0 auto
}

.pin_login .box {
	width: 100%;
	height: 50px;
	margin-top: -40%;
	line-height: 50px
}

.pin_login .box h2 {
	width: 100%;
	font-size: 24px;
	color: #FFF;
	text-align: center
}

.pin_login .box .pic_code {
	width: 100%;
	color: #FFF
}

.pin_login .box .pic_code input {
	width: 400px;
	margin: 5px 10px;
	padding: 10px;
	color: #f0941d;
	font-size: 24px;
	text-align: center;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	box-shadow: inset -5px 5px 5px -5px rgba(0, 0, 0, 0.5)
}

.pin_login .box .login_btn {
	margin-top: 20px;
	padding: 10px 50px;
	color: #FFF;
	font-size: 18px;
	background: #282828;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5)
}

.container.flex_center {
	flex-flow: nowrap;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	height: 100vh;
	overflow: hidden
}

.container.flex_center>section {
	position: relative
}

.container.flex_center .title {
	position: sticky;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	padding: 10px 5px;
	text-align: center;
	font-size: 18px;
	white-space: nowrap;
	background: #FFF;
	border-bottom: 1px solid #C4C4C4
}

.left_bar {
	width: 85px;
	height: 100vh;
	padding: 10px 0;
	color: #f0941d;
	text-align: center;
	background: #282828
}

.left_bar .date {
	font-size: 32px;
	letter-spacing: -0.5px
}

.left_bar .logo {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 10px 5px
}

.left_bar .logo img {
	width: 100%;
	height: auto
}

.left_bar .tab {
	margin-top: 20px
}

.left_bar .tab a {
	display: block;
	padding: 15px 0;
	color: #979797;
	font-size: 18px;
	line-height: 32px
}

.left_bar .tab a i {
	font-size: 24px
}

.left_bar .tab a.current {
	color: #FFF;
	background: #f0941d
}

.left_bar .tab select {
	display: none;
	width: 100%;
	padding: 10px;
	color: #f0941d;
	text-align: center;
	text-align-last: center;
	background: none;
	border: 2px solid #f0941d
}

.left_bar .tab a.current+select {
	display: block
}

.menu_category {
	width: 160px;
	height: 100vh;
	background: #FFF;
	border-right: 1px solid #C4C4C4;
	overflow-y: auto;
	overflow-x: hidden
}

.menu_category .category {
	width: 100%
}

.menu_category .category a {
	display: block;
	padding: 13px 20px;
	font-size:19px;
}

.menu_category .category a.current {
	color: #f0941d
}

.main_menu {
	width: calc(75% - 245px);
	height: 100vh;
	background: #FFF;
	border-right: 1px solid #C4C4C4
}

.main_menu .product {
	width: 100%;
	height: 50vh;
	padding: 0;
	overflow-y: auto;
	overflow-x: hidden
}

.main_menu .product .flex_center.flex_wrap {
	
	margin: 10px 0;
	padding: 0 10px;
	justify-content: flex-start;
}

.main_menu .product .flex_center.flex_wrap a {
	font-size: 23px;
	width: 32%;
	margin: 8px 0 7px 0;
	margin: 2px;
	padding: 10px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	background: #F6F6F6
}

.main_menu .product .flex_center.flex_wrap a .price {
	display: block;
	color: #CB5A4B;
	font-size: 12px
}

.main_menu .product .flex_center.flex_wrap a .price b {
	font-size: 18px;
	font-weight: normal
}

.main_menu .product .flex_center.flex_wrap a.current {
	background: #FFF;
	border: 2px solid #f0941d
}

.main_menu .add_option {
	width: 100%;
	height: 50%;
	padding: 10px 15px;
	background: #F6F6F6;
	overflow-y: auto;
	overflow-x: hidden
}

.main_menu .add_option .group {
	margin: 10px 0
}

.main_menu .add_option .group .tag {
	display: inline-block;
	margin-right: 10px
}

.main_menu .add_option .swiper-container {
	width: 100%;
	height: calc(100% - 30px);
	overflow: visible
}

.main_menu .add_option .swiper-slide {
	display: flex;
	flex-flow: wrap;
	justify-content: space-around;
	align-items: flex-start
}

.main_menu .add_option .swiper-slide a {
	width: calc(20% - 10px);
	height: calc(25% - 15px);
	margin: 8px 5px 7px 5px;
	padding: 8px;
	line-height: 18px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	background: #FFF;
	overflow: hidden
}

.main_menu .add_option .swiper-slide a.fixed_item {
	color: #FFF;
	background: #CB5A4B
}

.main_menu .add_option .swiper-slide a.current {
	color: #FFF;
	background: #f0941d
}

.main_menu .add_option .swiper-pagination {
	bottom: -45px
}

.main_menu .add_option .swiper-pagination-bullet {
	width: 40px;
	height: 40px;
	margin: 0 10px;
	color: #f0941d;
	text-align: center;
	line-height: 36px;
	font-size: 18px;
	background: #FFF;
	border: 2px solid #f0941d;
	opacity: 1
}

.main_menu .add_option .swiper-pagination-bullet-active {
	color: #FFF;
	background: #f0941d
}

.add_option2 {
	width: 100%;
	height: 50%;
	padding: 10px 15px;
	background: #F6F6F6;
	overflow-y: auto;
	overflow-x: hidden
}

.add_option2 .group {
	margin: 10px 0
}

.add_option2 .group .tag {
	display: inline-block;
	margin-right: 10px
}

.add_option2 .swiper-container {
	width: 100%;
	height: calc(100vh-30px);
	overflow: visible
}

.add_option2 .swiper-slide {
	display: flex;
	flex-flow: wrap;
	justify-content: space-around;
	align-items: flex-start
}

.add_option2 .swiper-slide a {
	/* width: calc(20% - 10px);
	height: calc(25% - 15px); */
	margin: 8px 5px 7px 5px;
	padding: 8px;
	line-height: 18px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	background: #FFF;
	overflow: hidden
}

.add_option2 .swiper-slide a.fixed_item {
	color: #FFF;
	background: #CB5A4B
}

.add_option2 .swiper-slide a.current {
	color: #FFF;
	background: #f0941d
}

.add_option2 .swiper-pagination {
	bottom: -80px
}

.add_option2 .swiper-pagination-bullet {
	width: 40px;
	height: 40px;
	margin: 0 10px;
	color: #f0941d;
	text-align: center;
	line-height: 36px;
	font-size: 18px;
	background: #FFF;
	border: 2px solid #f0941d;
	opacity: 1
}

.add_option2 .swiper-pagination-bullet-active {
	color: #FFF;
	background: #f0941d
}


.order_cart {
	width: 25%;
	height: 100vh;
	background: #FFF
}

.the_radio,
.the_checkbox {
	display: inline-block
}

.the_radio input[type=checkbox],
.the_checkbox input[type=checkbox],
.the_radio input[type=radio],
.the_checkbox input[type=radio] {
	display: none
}

.the_radio label,
.the_checkbox label {
	display: block;
	min-width: 30px;
	margin: 5px;
	padding: 5px 15px;
	border: 2px solid #C4C4C4;
	border-radius: 25px;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	cursor: pointer
}

.the_radio input[type=radio]:checked+label,
.the_checkbox input[type=radio]:checked+label,
.the_radio input[type=checkbox]:checked+label,
.the_checkbox input[type=checkbox]:checked+label {
	color: #FFF;
	border-color: #f0941d;
	background-color: #f0941d
}

.crumbs {
	margin: 10px 0
}

.sys_btn {
	position: sticky;
	bottom: -10px;
	left: 0;
	right: 0;
	margin: 20px auto 0 auto;
	padding: 10px;
	background: #FFF
}

.sys_btn .confirm {
	display: block;
	min-width: 150px;
	padding: 10px 20px;
	color: #FFF;
	text-align: center;
	background: #f0941d;
	border-radius: 25px;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px
}

.sys_btn2 {
	position: sticky;
	bottom: -10px;
	left: 0;
	right: 0;
	margin: 80px auto 0 auto;
	padding: 10px;
	background: #FFF
}

.sys_btn2 .confirm {
	display: block;
	min-width: 150px;
	padding: 10px 20px;
	color: #FFF;
	text-align: center;
	background: #f0941d;
	border-radius: 25px;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px
}

.food_items {
	position: relative;
	padding-bottom: 50px
}

.food_items .crumbs {
	margin: 10px 0
}

.food_items .title {
	margin: 20px 0 5px 0;
	font-weight: bold
}

.food_items .title .must {
	color: #f0941d
}

.food_memo {
	position: relative;
	padding-bottom: 50px
}

.food_memo textarea {
	margin: 10px 0;
	padding: 10px;
	width: 100%;
	height: 200px;
	background: #F6F6F6;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px
}

.delete_all {
	position: sticky;
	top: 0;
	height: 45px;
	text-align: center;
	background: #FFF;
	border-bottom: 1px solid #C4C4C4;
	border-top: 1px solid #C4C4C4;

}

.delete_all a {
	display: inline-block;
	padding: 10px;
	color: #f0941d;
	text-decoration: underline
}

.cart {
	height: calc(97% - 60px);
	overflow-y: auto;
	overflow-x: hidden
}

.cart a {
	display: block;
	position: relative;
	padding: 13px
}

.cart a .name {
	margin-bottom: 5px
}

.cart a .options {
	margin-bottom: 5px
}

.cart a .options i {
	display: inline-block;
	padding: 2px 5px;
	margin: 0 3px 3px 0;
	font-size: 13px;
	font-style: normal;
	color: #979797;
	border: 1px solid #C4C4C4;
	border-radius: 25px;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px
}

.cart a .delete {
	display: none;
	position: absolute;
	width: 30px;
	height: 30px;
	padding: 5px;
	right: -2px;
	top: -2px;
	color: #FFF;
	font-size: 20px;
	text-align: center;
	line-height: 30px;
	background: #f0941d
}

.cart a .subtotal {
	justify-content: space-between
}

.cart a .subtotal .input_quantity {
	border: 1px solid #C4C4C4
}

.cart a .subtotal .input_quantity button {
	font-size: 13px;
	height: 20px;
	color: #474747
}

.cart a .subtotal .input_quantity input {
	width: 35px;
	text-align: center
}

.cart a .subtotal .price {
	white-space: nowrap;
	font-size: 13px
}

.cart a .subtotal .price del {
	color: #C4C4C4;
	font-size: 13px
}

.cart a .subtotal .price b {
	font-size: 20px
}

.cart a.current {
	border: 2px solid #f0941d
}

.cart a.current .delete {
	display: block
}

.total {
	margin-top:100px;
	position: relative;
	height: calc(40% - 90px);
	padding-bottom: 60px;
	border-top: 1px solid #C4C4C4;
	/* margin-top:50px */
}

.total .flex_center.flex_wrap {
	width: 100%;
	height: 100%;
	padding: 10px;
	justify-content: space-between;
	overflow-y: scroll;
	overflow-x: hidden
}

.total .flex_center.flex_wrap>div {
	width: 100%;
	justify-content: space-between;
	margin:5px;
}

.total .flex_center.flex_wrap>div .discount {
	color: #CB5A4B
}

.total .flex_center.flex_wrap>div span>b {
	font-size: 20px
}

.packageNoteTextButton{
	padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
    color: #f0941d;
    background-color: white;
    border-radius: 10px;
    border: #f0941d 1px solid;
    width: 30%;
    margin: 5px;
}

.packageNoteTextButton2{
	padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
    color: #A34054;
    background-color: white;
    border-radius: 10px;
    border: #A34054 1px solid;
    width: 30%;
    margin: 5px;
}


.TextareaNumber{
	font-size: 17px;
    height: 30px;
    background-color: white;
    border: 0.5px dashed gainsboro;
}

.total .submit {
	position: absolute;
	width: 100%;
	height: 60px;
	padding: 10px;
	/* bottom: 0 */
	bottom:calc(100%);
	left: 0;
	right: 0;
	color: #FFF;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	line-height: 40px;
	background: #f0941d
}

.finish_msg h1 {
	margin: 20px auto;
	color: #f0941d;
	font-size: 32px;
	font-weight: bold;
	text-align: center
}

.finish_msg h2,
.finish_msg h3 {
	font-size: 24px
}

.finish_msg h4 {
	margin-top: 20px;
	color: #979797;
	border-bottom: 1px solid #C4C4C4
}

.finish_msg .order {
	height: 60%;
	overflow-y: auto;
	overflow-x: hidden
}

.finish_msg .order a {
	display: block;
	position: relative;
	padding: 15px;
	border-bottom: 1px solid #C4C4C4
}

.finish_msg .order a .name {
	margin-bottom: 5px
}

.finish_msg .order a .options {
	margin-bottom: 5px
}

.finish_msg .order a .options i {
	display: inline-block;
	padding: 2px 5px;
	margin: 0 3px 3px 0;
	font-size: 13px;
	font-style: normal;
	color: #979797;
	border: 1px solid #C4C4C4;
	border-radius: 25px;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px
}

.finish_msg .order a .subtotal {
	justify-content: space-between
}

.finish_msg .order a .subtotal .input_quantity::before {
	content: "x"
}

.finish_msg .order a .subtotal .price {
	white-space: nowrap;
	color: #CB5A4B;
	font-size: 13px
}

.finish_msg .order a .subtotal .price del {
	color: #C4C4C4;
	font-size: 13px
}

.finish_msg .order a .subtotal .price b {
	font-size: 20px
}

.plus_and_minus{
    width:auto
}

/* 這邊是自訂scroll bar滾度條 */
.frame { 
	overflow-y: auto; 
	/* border: 1px solid black;  */
	height: 40vh; 
	width: 100%;
	/* line-height: 1em;  */
} 
.frame::-webkit-scrollbar { 
	-webkit-appearance: none; 
} 
/* .frame::-webkit-scrollbar:vertical { 
	width: 11px; 
}  */
.frame::-webkit-scrollbar-thumb { 
	border-radius: 8px; border: 2px solid white; background-color: rgba(0, 0, 0, .5); 
}


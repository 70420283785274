#modal {
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    left: 0;
    top: 0;
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
    /* Enable scroll if needed */
    z-index: 2;
    /* Sit on top */
    font-family: roboto, noto sans tc, microsoft jhenghei, sans-serif, serif;
    font-weight: normal;
    letter-spacing: 0.5px;
    background-color: #000000;
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.5);
    /* Black w/ opacity */
  }
  #modal .modalContainer {
    width: 400px;
    padding: 20px;
    margin: auto;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
  }
  #modal .modalContainer .modalClose {
    top: 10px;
    right: 15px;
    position: absolute;
    color: #828282;
    z-index: 3;
    font-size: 24px;
  }
  #modal .modalContainer .modalClose:hover,
  #modal .modalContainer .modalClose:focus {
    color: #333;
    text-decoration: none;
    cursor: pointer;
  }
  #modal .modalContainer .modalTitle {
    color: #333;
    font-size: 24px;
  }
  #modal .modalContainer .modalContent {
    margin-top: 30px;
    color: #828282;
    font-size: 18px;
    text-align: center;
  }
  #modal .modalContainer .modalSubmit {
    width: 100%;
    padding: 10px 0;
    margin-top: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: #282828;
    font-size: 18px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  #modal .modalContainer .modalSubmit:hover {
    background: #4F4F4F;
    cursor: pointer;
  }
  